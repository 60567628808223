import { get } from '../utils/api';

export type Company = {
  companyCode: string;
  companyName: string;
  webPermission: [];
};

export type saleORG = {
  [key: string]: any;
  saleOrgCode: string;
  storeName: string;
};

export interface ICompanyServices {
  getListCompany(userName: string): Promise<Array<Company>>;
  getListSaleORG(
    userName: string,
    companyCode: string
  ): Promise<Array<saleORG>>;
}

export function createCompanyServices(url: string): ICompanyServices {
  return {
    getListCompany: async (userName: string): Promise<Array<Company>> => {
      return (
        await get(
          `Permission/Auth/GetListCompanyByUsername?username=${userName}`
        )
      ).data;
    },
    getListSaleORG: async (
      userName: string,
      companyCode: string
    ): Promise<Array<saleORG>> => {
      return (
        await get(
          `Permission/Auth/GetSaleOrgByCompanyCode?CompanyCode=${companyCode}&UserName=${userName}`
        )
      ).data;
    },
  };
}
