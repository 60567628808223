import { createServices } from 'services/services';
import { createUserPresenter } from 'stores/user_store';
import { createAllocateMaterialsPresenter } from './allocateMaterials_store';
import { createCompanypresenter } from './company_store';
import { createCommonPresenter } from './common_store';

// khỏi tại services
export const services = createServices('');

// khởi tạo presenter và store của user
export const userPresenter = createUserPresenter(services);
export const userStore = userPresenter.createStore();

// khởi tạo presenter và store của company
export const companyPresenter = createCompanypresenter(services);
export const companyStore = companyPresenter.createStore();

// khởi tạo presenter và store của allocateMaterials
export const allocateMaterialsPresenter = createAllocateMaterialsPresenter(services);
export const AllocStore = allocateMaterialsPresenter.createStore();

// khởi tạo presenter và store của company
export const commonPresenter = createCommonPresenter(services);
export const commonStore = commonPresenter.createStore();
