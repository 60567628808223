import React, { useEffect, useState } from "react";
import { Highlighter, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link } from "react-router-dom";
import { ChildMenu, User } from "services/user_services";
import "./searchBar.css";

type ParentMenu = {
  menuId: string;
  menuName: string;
};

const SearchBarInSideBar = () => {
  const data = JSON.parse(localStorage.getItem("user_session") || "{}") as User;
  const childMenu = data.webPermission?.pageModel;
  const parentMenu = data.webPermission?.menuModel;
  const [selected, setSelected] = useState([]);
  const options: ChildMenu[] = childMenu;
  const parents: ParentMenu[] = parentMenu;
  const props: any = {};

  switch (selected) {
    default:
      props.renderMenuItemChildren = (selectedItem: any, { text }: any) => (
        <Link
          className="relative font-bold text-[12px]"
          to={selectedItem.pageUrl}
          key={selectedItem.menuId}
        >
          <div className={"uppercase"}>
            <Highlighter search={text}>{selectedItem.pageName}</Highlighter>
          </div>
          <div>
            {parents.map((item,index) => {
              if (item.menuId === selectedItem.menuId)
                return (
                  <span key={index} className="text-gray-500 font-medium">
                    {item.menuName}
                  </span>
                );
            })}
          </div>
        </Link>
      );
      break;
  }


  

  return (
    <>
      <Typeahead
        autoFocus
        {...props}
        className="m-1 outline-none border-none p-2"
        id="basic-example"
        labelKey="pageName"
        onChange={setSelected}
        options={options}
        placeholder="Tìm kiếm..."
        selected={selected}
        
        minLength={1}
        inputProps={{
          className: "!outline-none text-sm font-semibold text-gray-600",
        }}
      />
    </>
  );
};
export default SearchBarInSideBar;
