import { createAllocateMaterialsService, IAllocateMaterialsServices } from './allocateMaterials_services';
import { createCompanyServices, ICompanyServices } from './company_services';
import { createUserServices, IUserServices } from './user_services';
import { createCommonServices, ICommonServices } from './common_servieces';

export interface IServices {
  userServices: IUserServices;
  companyServices: ICompanyServices;
  allocateMaterialsServices: IAllocateMaterialsServices;
  commonServices: ICommonServices;
}

export function createServices(url: string): IServices {
  return {
    userServices: createUserServices(url),
    companyServices: createCompanyServices(url),
    allocateMaterialsServices: createAllocateMaterialsService(),
    commonServices: createCommonServices(),
  };
}
