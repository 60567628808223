import { post, put, get } from 'utils/api';
import { DataResponse } from './response';

//========================== SLOC ===============================
export interface CommonSlocModel {
  slocId: string;
  sloc: string;
  slocDisplay: string;
  plant: string;
  warehouseNo: string;
  defaultStorageBin?: string;
  defaultStorageBinId?: string;
}
//================================================================

export interface ICommonServices {
  getSloc(sloc: string, plant: string): Promise<DataResponse<Array<CommonSlocModel>>>;
}

export function createCommonServices(): ICommonServices {
  return {
    getSloc: async (sloc: string, plant: string) => {
      return (await get(`MES/Common/GetSloc?Sloc=${sloc}&Plant=${plant}`)).data;
    },
  };
}
