import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createApp } from './create_app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import { MaintainSession } from 'utils/common';

MaintainSession();
const App = createApp();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <Provider>
        <App />
    </Provider>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
