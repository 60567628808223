import { runInAction } from 'mobx';
import { ICommonServices, CommonSlocModel } from 'services/common_servieces';

export type CommonStore = {
  slocResponse: {
    isSuccess: boolean;
    message: string;
    data: CommonSlocModel[];
  };
};

export interface ICommonPresenter {
  createStore(): CommonStore;
  getSloc(store: CommonStore, sloc: string, plant: string): Promise<void>;
}

export function createCommonPresenter({ commonServices }: { commonServices: ICommonServices }): ICommonPresenter {
  return {
    createStore() {
      return {
        slocResponse: {
          isSuccess: false,
          message: '',
          data: [],
        },
      };
    },
    getSloc: async (store: CommonStore, sloc: string, plant: string) => {
      try {
        const response = await commonServices.getSloc(sloc, plant);
        runInAction(() => {
          store.slocResponse.data = response.data;
          store.slocResponse.isSuccess = response.isSuccess;
          store.slocResponse.message = response.message;
        });
      } catch (e) {
        console.log(e);
      }
    },
  };
}
