import { LayoutHOC } from 'base/ui/layout/layout';
import { ICompanyServices } from 'services/company_services';
import { IUserServices } from 'services/user_services';
import { createLogin } from './login';

export const createLoginPage = (
  layoutHOC: LayoutHOC,
  companyService: { companyServices: ICompanyServices },
  userServices: { userServices: IUserServices }
) => {
  return layoutHOC(createLogin(companyService, userServices));
};
