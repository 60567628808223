import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import moment, { Moment } from 'moment';

// Componets
import LoadingScreen from 'base/ui/components/LoadingScreen/loadingScreen';
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// Icons

// Stores
import { AllocStore, allocateMaterialsPresenter, companyPresenter, companyStore } from 'stores/root_store';

// Models
// import { appointments } from './month-appointments';
import { RepostModel } from './models';
import { ReportAllocate } from 'services/allocateMaterials_services';
import { Company } from 'services/company_services';

// Style

const Style = {
  MuiTableCellRoot: {
    fontSize: '13px',
    fontWeight: 700,
    padding: '5px 10px',
    lineHeight: '20px',
    color: 'white',
  },
  borderInherit: {
    borderCollapse: 'inherit',
  },
  MuiTableCellRootTd: {
    fontSize: '13px',
    padding: '5px',
    lineHeight: '20px',
    borderBottom: 'unset',
  },
  '.MuiOutlinedInput-root': {
    padding: 0,
  },
  '.MuiOutlinedInput-input': {
    fontSize: '13px',
  },
  '.MuiOutlinedInput-notchedOutline': { borderRadius: 0 },
};

export function createReport() {
  return observer(() => {
    // ===========================STATE==========================
    const [loading, setLoading] = useState(true);
    const [defaultCurrentDate, setDefaultCurrentDate] = useState<Date>(new Date());
    // list plant
    const [listCompany, setListCompany] = useState<Company[]>([]);
    const [companyCode, setCompanyCode] = useState('');
    const [appointments, setAppointments] = useState<ReportAllocate | undefined>(undefined);
    // ===========================FUNCTION==========================

    //==================== LOAD DỮ LIỆU =================================
    // lấy thông tin báo cáo
    const getRepost = async (date: Date, plant: string) => {
      await allocateMaterialsPresenter.getReportAllocate(AllocStore, {
        allocateDate: date,
        plant: plant,
      });
      if (AllocStore.reportAllocateResponse.isSuccess) {
        setAppointments(AllocStore.reportAllocateResponse.data);
      }
    };

    // Lấy danh sách plant
    const getListPlants = async () => {
      const data = JSON.parse(localStorage.getItem('user_session') || '{}');
      await companyPresenter.geListtCompany(companyStore, data.userName);
      setListCompany(companyStore.companies);
      setCompanyCode(companyStore.companies[0].companyCode);
    };

    useEffect(() => {
      const getData = async () => {
        await getListPlants();
        await getRepost(defaultCurrentDate, companyStore.companies[0].companyCode);
        setLoading(false);
      };
      getData();
    }, []);

    // Lấy Tháng
    const handleChangeMonth = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setLoading(true);
      if (value) {
        setDefaultCurrentDate(new Date(e.target.value));
        await getRepost(new Date(e.target.value), '1000');
      } else {
        setDefaultCurrentDate(new Date());
        await getRepost(new Date(), '1000');
      }
      setLoading(false);
    };
    // Xử lý thay đổi plant
    const handleChangePlant = async (e: SelectChangeEvent<string>) => {
      setLoading(true);
      setCompanyCode(e.target.value);
      await getRepost(defaultCurrentDate, e.target.value);
      setLoading(false);
    };

    return (
      <div className="w-full py-3 px-5">
        {loading && <LoadingScreen />}
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-md font-bold text-gray-800 uppercase">Báo cáo phân bổ</h3>
        </div>
        {/* Form nhập tìm kiếm */}
        <div className="w-full bg-white py-3 flex rounded mb-2 border border-neutral-300">
          <form className="w-full px-8 py-2 lg:grid lg:grid-cols-2 flex flex-col gap-2">
            {/* Ngày phân bổ*/}
            <div className="w-full sm:flex-row sm:items-center grid grid-cols-3">
              <label
                htmlFor="allocateDate"
                className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end "
              >
                Ngày phân bổ
              </label>
              <input
                type="month"
                id="allocateDate"
                value={moment(defaultCurrentDate).format('YYYY-MM')}
                onChange={handleChangeMonth}
                className="col-span-2 text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default focus-visible:outline-none focus:border-blue-500 caret-blue-600"
              />
            </div>

            <div className="w-full sm:flex-row sm:items-center grid grid-cols-3">
              <label htmlFor="status" className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                Plant
              </label>
              <FormControl className="col-span-2">
                <Select
                  id="status"
                  className="text-gray-700 text-default hover:ring-0 hover:outline-none hover:border-gray-300 !rounded-none border-gray-300 sm:ml-5 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                  displayEmpty
                  onChange={handleChangePlant}
                  value={companyCode}
                  sx={{ height: 35 }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {listCompany.length
                    ? listCompany.map((company, index) => (
                        <MenuItem value={company.companyCode} key={index}>
                          <span className="text-default">{company.companyName}</span>
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
          </form>
        </div>

        {/* =========== Table  ===============*/}

        <div className={`bg-white py-3 px-5 rounded border border-neutral-300`}>
          {/* Bảng ghi chú */}
          <TableContainer
            component={Paper}
            className="table-auto overflow-y-hidden space-collapse !w-fit text-default mb-3.5"
          >
            <Table aria-label="simple table" sx={Style.borderInherit}>
              <TableHead className="font-medium text-gray-700 text-default bg-gray-100">
                <TableRow>
                  <TableCell
                    align="center"
                    sx={[Style.MuiTableCellRoot]}
                    scope="col"
                    colSpan={2}
                    className={`
                           !py-0.25 px-2 border bg-primary/80 whitespace-nowrap`}
                  >
                    Chú thích
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="border !py-0.25" sx={[Style.MuiTableCellRootTd]}>
                    Đã phân bổ hết
                  </TableCell>
                  <TableCell
                    // #228b02
                    className="border !py-0.25 bg-[#4db6ac] min-w-[100px]"
                    sx={[Style.MuiTableCellRootTd]}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="border !py-0.25" sx={[Style.MuiTableCellRootTd]}>
                    Đã phân bổ 1 phần
                  </TableCell>
                  <TableCell
                    className="border !py-0.25 bg-[#ffb74d] min-w-[100px]"
                    sx={[Style.MuiTableCellRootTd]}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="border !py-0.25" sx={[Style.MuiTableCellRootTd]}>
                    Chưa phân bổ
                  </TableCell>
                  <TableCell
                    className="border !py-0.25 bg-[#acacac] min-w-[100px]"
                    sx={[Style.MuiTableCellRootTd]}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* Bảng chi tiết */}
          <div className="w-full py-1 overflow-x-auto">
            <TableContainer component={Paper} className="table-auto overflow-y-hidden space-collapse text-default">
              <Table aria-label="simple table" className="!w-full" sx={Style.borderInherit}>
                <TableHead className="font-medium text-gray-700  text-default bg-gray-100">
                  <TableRow>
                    <TableCell
                      sx={[Style.MuiTableCellRoot]}
                      scope="col"
                      align="center"
                      className={`
                           py-2 px-2 border  text-left bg-primary whitespace-nowrap`}
                    >
                      Ngày
                    </TableCell>
                    {appointments && appointments.slocs.length
                      ? appointments.slocs.map((item, index) => (
                          <TableCell
                            key={index}
                            sx={[Style.MuiTableCellRoot]}
                            scope="col"
                            align="center"
                            className={`
                           py-2 px-2 border bg-primary whitespace-nowrap`}
                          >
                            {item}
                          </TableCell>
                        ))
                      : ''}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointments && appointments.detailReports.length ? (
                    appointments.detailReports.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="border !text-center py-1" sx={[Style.MuiTableCellRootTd]}>
                          {moment(row.allocateDate).format('DD')}
                        </TableCell>
                        {row.slocReportAllocates.map((item, j) => (
                          <TableCell
                            key={j}
                            align="center"
                            className="border !text-center py-1 !text-default !text-white"
                            sx={[Style.MuiTableCellRootTd, { backgroundColor: item.color }]}
                          >
                            {item.percent}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} className="border !text-center py-1" sx={[Style.MuiTableCellRootTd]}>
                        Không tìm thấy dòng nào phù hợp
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  });
}
