import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ROUTES } from 'base/routes/routes';

import { observer } from 'mobx-react';
import * as rootStore from 'stores/root_store';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { createLoginLayout, createDefaultLayout } from 'base/ui/layout/layout';
import { createPages } from 'pages/create';
import { Header as HeaderView } from 'base/ui/layout/header/header';
import { Sidebar as SidebarView } from 'base/ui/layout/sideBar/sideBar';
import { Footer as FooterView } from 'base/ui/layout/footer/footer';

export function createApp() {
  const services = rootStore.services;
  // khởi tạo header, sidebar, footer
  const Header = observer(() => <HeaderView />);
  const Sidebar = observer(() => <SidebarView />);
  const Footer = observer(() => <FooterView />);

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ccc',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#3b82f6',
              borderWidth: '1px',
            },
          },
        },
      },
    },
  });

  // Tạo ra 2 layout
  // Layout cho trang login chỉ có content không có sidebar, header, footer
  const layoutLogin = createLoginLayout();
  // Layout mặc định sẽ có đầy đủ sidebar, header, footer
  const layoutDefault = createDefaultLayout(Header, Sidebar, Footer);
  // khỏi tạo các trang với layout tương ứng
  const { LoginPage } = createPages(layoutLogin, services);
  const { HomePage, AllocateMaterialsPage, EditAllocateMaterialsPage, ReportPage } = createPages(
    layoutDefault,
    services,
  );

  return () => (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {/* Trang đăng nhập */}
            <Route path={ROUTES.LOGIN.BASE} element={<LoginPage />} />
            {/* Trang chủ */}
            <Route path={ROUTES.HOME} element={<HomePage />} />
            <Route path={ROUTES.ALLOCATE_MATERIALS.BASE} element={<AllocateMaterialsPage />} />
            {/* Màn hình phân bổ */}
            <Route path={ROUTES.ALLOCATE_MATERIALS.EDIT.BASE} element={<EditAllocateMaterialsPage />} />
            {/* Báo cáo phân bổ */}
            <Route path={ROUTES.REPORT.BASE} element={<ReportPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      {/* React toastify // hiển thị thông báo */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
