// link api
// export const BASE_API_URL = 'https://imes-api.truongthanh.com/api/v2';
// export const BASE_API_URL = 'http://192.168.1.123:3000/api/v2';
export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
// số item trên trang (mặc định)
export const PAGE_SIZE_DEFAULT = 10;

export const URL_PRINT = 'https://imes.truongthanh.com/';

// url trang chủ MVC
//export const URL_HOME = 'https://imes.truongthanh.com/';
export const URL_HOME = 'https://ttf-mes-fe.isdcorp.vn/';

export const CRYPTED_KEY = 'XJenBr2oWxP7UdBRSkyIo1mqB4O6UBs3';
