import { useEffect, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { ChildMenu } from 'services/user_services';
import { userPresenter, userStore } from 'stores/root_store';

type MenuItemProps = {
  open: boolean;
  menuId: string;
  menuName: string;
  icon: string;
  childMenu: Array<ChildMenu>;
};

const MenuItem = ({ open, menuId, menuName, icon, childMenu }: MenuItemProps) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  let path = window.location.pathname;

  const handleDropdown = () => {
    if (open) {
      setSubmenuOpen(!submenuOpen);
    }
  };

  return (
    <>
      <li
        onClick={handleDropdown}
        className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer py-3 px-4 hover:bg-sidebar_active'
        }`}
      >
        <span className="text-md block float-left select-none">
          <i className={icon} />
        </span>
        {/* nếu side bar đang đóng thì ẩn các items */}
        <span className={`text-sm flex-1 whitespace-nowrap ${!open && 'hidden'} duration-400`}>{menuName}</span>
        {/* nếu side bar đang mở thì hiện nút dropdown */}
        {/* nếu submenu mở thì dropdowm icon hướng lên trên (rotate 180 deg) */}
        {open && <HiChevronDown className={`duration-200 ${submenuOpen && 'rotate-180'}`} />}
      </li>
      {submenuOpen && open && (
        <ul className="bg-[#2c3b41]">
          {childMenu.map((item: ChildMenu, index) => {
            if (item.menuId === menuId) {
              // if (process.env.NODE_ENV === 'development') {
              //   return (
              //     <Link to={`${item.pageUrl}`} className='w-full' key={index}>
              //       <li
              //         className={`text-slate-400 text-sm flex items-center gap-x-4 cursor-pointer select-none p-2 px-5 hover:text-gray-200 ${
              //           path.includes(item.pageUrl) ? '!text-gray-200' : ''
              //         }`}
              //       >
              //         <span>{item.pageName}</span>
              //       </li>
              //     </Link>
              //   );
              // }
              //  else {
              //   return (
              //     <a
              //       href={`${item.domainConfigUrl}${item.pageUrl}`}
              //       className='w-full'
              //       key={index}
              //     >
              //       <li className='text-slate-400 text-sm flex items-center gap-x-4 cursor-pointer select-none p-2 px-5 hover:text-gray-200'>
              //         <span>{item.pageName}</span>
              //       </li>
              //     </a>
              //   );
              // }
              return (
                <Link to={`${item.pageUrl}`} className="w-full" key={index}>
                  <li
                    className={`text-slate-400 text-sm flex items-center gap-x-4 cursor-pointer select-none p-2 px-5 hover:text-gray-200 ${
                      path.includes(item.pageUrl) ? '!text-gray-200' : ''
                    }`}
                  >
                    <span>{item.pageName}</span>
                  </li>
                </Link>
              );
            }
          })}
        </ul>
      )}
    </>
  );
};

export default MenuItem;
