import './loadingScreen.css';

function LoadingScreen() {
    return (
        <div className="fixed w-full h-full -ml-5 -mt-2 z-[100]">
            <div className="w-full h-full flex justify-center items-center bg-white/50">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;
