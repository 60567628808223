import { IServices } from 'services/services';
import { LayoutHOC } from 'base/ui/layout/layout';
import { createHomePage } from './home/create';
import { createLoginPage } from './login/create';
import { createAllocateMaterialsPage } from './AllocateMaterials/create';
import { createEditAllocateMaterialsPage } from './AllocateMaterials/Edit/create';
import { createReportPage } from './Report/create';

export function createPages(layoutHOC: LayoutHOC, services: IServices) {
  return {
    HomePage: createHomePage(layoutHOC),
    LoginPage: createLoginPage(layoutHOC, services, services),
    AllocateMaterialsPage: createAllocateMaterialsPage(layoutHOC),
    EditAllocateMaterialsPage: createEditAllocateMaterialsPage(layoutHOC),
    ReportPage: createReportPage(layoutHOC),
  };
}
