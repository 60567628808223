import { ChangeEvent, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatNumber } from "utils/formatHelper";
import { toast } from "react-toastify";
import moment, { updateLocale } from "moment";

// Componets
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import LoadingScreen from "base/ui/components/LoadingScreen/loadingScreen";
import { IoArrowBackCircle } from "react-icons/io5";

//Store - services - models
import { AllocStore, allocateMaterialsPresenter } from "stores/root_store";
import {
  AllocateMaterialsModel,
  DetailAllocateMaterial,
  DetailAllocateModel,
  WarehouseTransactionModel,
} from "services/allocateMaterials_services";
import { AMDetailRequest } from "services/allocateMaterials_services";
import { HiArrowNarrowRight } from "react-icons/hi";
import { getUserSession } from "utils/localStorageHelper";

interface Allocate extends WarehouseTransactionModel {
  allocate: number;
}

interface Distribution {
  currentAllocatedQuantity: number;
  allocatedQuantityForUpdate: number;
  quantity: number;
  so?: string | null;
  wbs?: string | null;
}

interface SaveRequest {
  taskId: string;
  productCode: string;
  allocateAmount: number;
  unit: string;
  sloc: string;
  batch: string;
  allocateDate: string;
  soNumber: string | null;
  soLine: string | null;
  wbs: string | null;
  stepCode: string;
}

interface UpdateRequest {
  quantity: number;
  soNumber: string | null;
  soLine: string | null;
  wbs: string | null;
  taskId: string;
}

export function createEditAllocateMaterials() {
  const column = [
    {
      columnName: "STT",
      align: "text-center",
      visible: true,
      table1: true,
    },
    { columnName: "LSX", align: "text-center", visible: true },

    {
      columnName: "SO/SO Line | WBS",
      align: "text-center",
      visible: true,
      table1: true,
    },
    { columnName: "Sản phẩm", align: "text-center", visible: true },
    {
      // columnName: "Số lượng LSX"
      columnName: (
        <>
          <div>Số lượng</div>
          <div>LSX</div>
        </>
      ),
      align: "text-center",
      visible: true,
    },
    { columnName: "Số lượng ghi nhận (Sản phẩm)", align: "text-center", visible: false },
    {
      // columnName: 'SL YC theo lệnh',
      columnName: (
        <>
          <div>SL YC</div>
          <div>theo lệnh</div>
        </>
      ),

      align: "text-center",
      visible: true,
    },
    {
      // columnName: "SL đã phân bổ",
      columnName: (
        <>
          <div>SL đã</div>
          <div>phân bổ</div>
        </>
      ),
      align: "text-center",
      visible: true,
    },
    {
      columnName: "SL còn lại",
      align: "text-center",
      visible: true,
    },
    {
      columnName: "Tồn kho",
      align: "text-center",
      visible: false,
      table1: true,
    },
    {
      columnName: "SL phân bổ",
      align: "text-center",
      visible: true,
      table1: true,
    },
    {
      columnName: "ĐVT",
      align: "text-center",
      visible: true,
      table1: true,
    },
    {
      columnName: "% Tổng quan",
      align: "text-center",
      visible: true,
    },
  ];

  const Style = {
    MuiTableCellRoot: {
      fontSize: "12px",
      fontWeight: 700,
      padding: "5px 5px",
      lineHeight: "20px",
      color: "white",
    },
    borderInherit: {
      borderCollapse: "inherit",
    },
    MuiTableCellRootTd: {
      fontSize: "13px",
      padding: "3px 8px",
      lineHeight: "20px",
      borderBottom: "unset",
    },
    ".MuiOutlinedInput-root": {
      padding: 0,
    },
    ".MuiOutlinedInput-input": {
      fontSize: "13px",
    },
    ".MuiOutlinedInput-notchedOutline": { borderRadius: 0 },
  };

  return observer(() => {
    // Navigate
    const location = useLocation();
    const navigate = useNavigate();
    // ======================VALIDATION===============================

    // ===========================STATE==========================
    // lấy chi tiết phân bổ từ
    const {
      productCode = "",
      batch = "",
      sloc = "",
      plant = "",
      stepCode = "",
      allocateDate = "",
      isUpdate = false,
    } = location.state as AMDetailRequest;
    // lưu lại details phân bổ sau khi call api (có thể sử dụng trực tiếp Global state)
    const [detailsAM, setDetailAM] = useState<DetailAllocateModel | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [listSOWBS, setListSOWBS] = useState<WarehouseTransactionModel[]>([]);
    // showTable "Các LSX ghi nhận trong ngày" sao khi bấm nút phân bổ
    const [showTable, setShowTable] = useState(false);
    // Lưu lại số lượng phân bổ để kiểm tra
    const [allocatedQuantities, setAllocatedQuantities] = useState<Distribution[]>([]);
    // Số lượng phân bổ của table 2
    const [allocated2Quantities, setAllocated2Quantities] = useState<number[]>([]);
    // lưu tạm xong mới được post SAP
    const [isPost, setPost] = useState(false);

    const [userSession, setUserSession] = useState({});

    const [pagePermissions, setPagePermissions] = useState([]);

    useEffect(() => {
      const session = getUserSession();
      // console.log("-- TIEN getUserSession", session);

      const currentUrl = window.location.href;
      const matchingPageModel = session?.webPermission?.pageModel.find((page: any) =>
        currentUrl.includes(page.pageUrl)
      );

      if (matchingPageModel) {
        // console.log("Matching pageModel item:", matchingPageModel);

        const matchingPagePermissions = session?.webPermission?.pagePermissionModel.filter(
          (permission: any) => permission.pageId === matchingPageModel.pageId
        );

        setPagePermissions(matchingPagePermissions);

        // console.log("Matching matchingPagePermissions item:", matchingPagePermissions);
      } else {
        // console.log("No matching pageModel item found");
      }
    }, []);

    // ===========================FUNCTION==========================

    // USE EFFECT ==================================================
    // Lấy details của các fields và table 2
    const getAllocateMaterialDetail = async () => {
      await allocateMaterialsPresenter.getAMDetail(AllocStore, {
        productCode: productCode,
        batch: batch,
        sloc: sloc,
        plant: plant,
        stepCode: stepCode,
        allocateDate: allocateDate,
      });
      if (AllocStore.detailResponse.isSuccess) {
        const tempData = AllocStore.detailResponse.data;
        setDetailAM(AllocStore.detailResponse.data);
        // setLoading(false);
      }
    };

    // Lấy chi tiết so và wbs table 1 theo số batch
    const getListSOWBSByBatch = async () => {
      await allocateMaterialsPresenter.getListSOWBSByBatch(AllocStore, {
        productCode: productCode,
        plant: plant,
        sloc: sloc,
        batchNumber: batch,
      });
      if (AllocStore.listSOWBSResponses.isSuccess) {
        setListSOWBS(AllocStore.listSOWBSResponses.data);
        // setLoading(false);
      }
    };

    // lấy thông tin lưu tạm
    const getTempAllocateFromServer = async () => {
      // MES/AllocateMaterials/get-allocate
      await allocateMaterialsPresenter.getTempAllocate(AllocStore, {
        productCode: productCode,
        batch: batch,
        sloc: sloc,
        plant: plant,
        stepCode: stepCode,
        allocateDate: allocateDate,
      });
      if (AllocStore.tempAllocate.isSuccess) {
        const updatedTable2Quantities = [...allocated2Quantities];
        // khởi tạo mảng quantity2 = 0
        AllocStore.detailResponse.data?.detailAllocateMaterials.forEach((item, index) => {
          updatedTable2Quantities[index] = 0;
        });
        // lấy số lượng lưu tạm gán cho quantity2
        AllocStore.detailResponse.data?.detailAllocateMaterials.map((item, index) => {
          // kiểm tra từ phần tử
          const indexFinded = AllocStore.tempAllocate.data.findIndex((t) => t.taskId === item.taskId);
          // nếu tìm thấy thì set quantity2 tại vị trí thứ index giá trị tempAllocate thứ indexFinded
          if (indexFinded >= 0) {
            updatedTable2Quantities[index] = AllocStore.tempAllocate.data[indexFinded].quantity;
          }
        });
        setAllocated2Quantities(updatedTable2Quantities);
        // setLoading(false);
      }
    };

    // tự động phân bổ (mặc định)
    // 1. NVL tồn kho SAP
    const autoAllocateSLPhanBo = () => {
      let allocatedQuantitiesTemp: Distribution[] = [];
      const copiedTable2Quantities = [...allocated2Quantities];

      // define var to store AllocStore.detailResponse.data;
      const detailsAMTemp = AllocStore.detailResponse.data;
      const listWBSResponseTemp = AllocStore.listSOWBSResponses.data;
      const phanBoWBSDetail = detailsAMTemp?.phanBoWBSDetail;

      // khởi tạo mảng quantity2 = 0
      detailsAMTemp?.detailAllocateMaterials.forEach((item, index) => {
        copiedTable2Quantities[index] = 0;
        setAllocated2Quantities(copiedTable2Quantities);
      });

      let tongSoLuongPhanBo = detailsAMTemp!.allocateAmount;
      // listWBSResponseTemp.forEach((item, index) => {
      //   if (tongSoLuongPhanBo > 0) {
      //     if (tongSoLuongPhanBo > item.quantity) {
      //       // temp.push(item.quantity);
      //       allocatedQuantitiesTemp.push({
      //         allocatedQuantityForUpdate: isUpdate ? item.quantity : 0,
      //         currentAllocatedQuantity: Number(item.quantity.toFixed(3)),
      //         quantity: item.quantity,
      //         so: item.so,
      //         wbs: item.wbs,
      //       });
      //       tongSoLuongPhanBo -= item.quantity;
      //     } else {
      //       // temp.push(count);
      //       allocatedQuantitiesTemp.push({
      //         allocatedQuantityForUpdate: isUpdate ? tongSoLuongPhanBo : 0,
      //         currentAllocatedQuantity: Number(tongSoLuongPhanBo.toFixed(3)),
      //         quantity: item.quantity,
      //         so: item.so,
      //         wbs: item.wbs,
      //       });
      //       tongSoLuongPhanBo = 0;
      //     }
      //   } else {
      //     // temp.push(count);
      //     allocatedQuantitiesTemp.push({
      //       allocatedQuantityForUpdate: isUpdate ? tongSoLuongPhanBo : 0,
      //       currentAllocatedQuantity: Number(tongSoLuongPhanBo.toFixed(3)),
      //       quantity: item.quantity,
      //       so: item.so,
      //       wbs: item.wbs,
      //     });
      //   }
      // });

      listWBSResponseTemp.forEach((item) => {
        const soSolLine = `${item.so}/${item.soLine}`;
        const wbs = item.wbs;

        const matchingDetail = phanBoWBSDetail?.find(
          (detail) =>
            detail.sowbs === soSolLine ||
            detail.sowbs === wbs ||
            (soSolLine === "null/null" && wbs === null && detail.sowbs === "Tồn trơn")
        );
        let currentAllocatedQuantity = 0;
        if (matchingDetail) {
          currentAllocatedQuantity = matchingDetail.quantity ?? 0;

          // if (currentAllocatedQuantity === 0) {
          if (tongSoLuongPhanBo > 0) {
            currentAllocatedQuantity = item.quantity;
            tongSoLuongPhanBo -= item.quantity;
          } else {
            currentAllocatedQuantity = 0;
            // tongSoLuongPhanBo = 0;
          }
          // }
        } else {
          // Phân bổ vô tồn trơn: Nếu có mà không tìm thấy số lượng phân bổ thì lấy số lượng còn lại
          // if (!matchingDetail && tongSoLuongPhanBo > 0) {
          //   currentAllocatedQuantity = item.quantity;
          //   tongSoLuongPhanBo -= item.quantity;
          // }

          // if (!matchingDetail) {
          //   currentAllocatedQuantity = 0;
          // }
        }

        allocatedQuantitiesTemp.push({
          allocatedQuantityForUpdate: isUpdate ? currentAllocatedQuantity : 0,
          currentAllocatedQuantity: Number(currentAllocatedQuantity?.toFixed(3)),
          quantity: item.quantity,
          so: soSolLine,
          wbs: item.wbs,
        });
      });

      setAllocatedQuantities(allocatedQuantitiesTemp);
    };

    useEffect(() => {
      const getInit = async () => {
        await getAllocateMaterialDetail(); // detailsAM
        await getListSOWBSByBatch(); // listSOWBS
        autoAllocateSLPhanBo();
        if (isUpdate) {
          await getTempAllocateFromServer();
          setShowTable(true);
        }
        setLoading(false);
      };
      getInit();
    }, []);

    //================================================================================================

    // ======================== HANDLE===========================
    // lấy Số lượng phân bổ của tưng dòng
    const handleChangQuantity = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      // kiểm tra nếu value k rỗng thì value = e.taget.vale
      const value = (e.target.value && Number(e.target.value)) || 0;
      const updatedAllocatedQuantities = [...allocatedQuantities];
      if (listSOWBS[index].quantity < value) {
        updatedAllocatedQuantities[index].currentAllocatedQuantity = listSOWBS[index].quantity;
      } else {
        updatedAllocatedQuantities[index].currentAllocatedQuantity = value;
      }

      setAllocatedQuantities(updatedAllocatedQuantities);
      setShowTable(false);
      // reset lại quantity2
      const resetTable2Quantities = [...allocated2Quantities];
      for (let i = 0; i < resetTable2Quantities.length; i++) {
        resetTable2Quantities[i] = 0;
      }
      setAllocated2Quantities(resetTable2Quantities);

      // reset lại quantity
      const resetAllocatedQuantities = [...allocatedQuantities];
      for (let i = 0; i < resetAllocatedQuantities.length; i++) {
        resetAllocatedQuantities[i].allocatedQuantityForUpdate = 0;
      }
      setAllocatedQuantities(resetAllocatedQuantities);
    };

    // sự kiện cho button phân bổ nếu tổng bằng với số lượng cần phân bổ thì show bảng phân bổ
    // phanBoPress
    // 2. Các LSX ghi nhận trong ngày
    const handlePhanBoTrenXuong = () => {
      const sum = allocatedQuantities.reduce((total, item) => total + item.currentAllocatedQuantity, 0);
      if (detailsAM?.allocateAmount === Math.round(sum * 10 ** 3) / 10 ** 3) {
        // TODO auto phan bo tren xuong

        // Table ở trên listSOWBS (tồn kho)
        // Table ở trên allocatedQuantities (SL phân bổ)

        // Table ở dưới detailsAM (danh sách)
        // Table ở dưới allocated2Quantities (SL phân bổ)

        // From this list item allocatedQuantities, get the index of detailsAM.detailAllocateMaterials by so or wbs if found then update the allocated2Quantities with the found index with the value  currentAllocatedQuantity of the allocatedQuantities

        // Iterate through allocatedQuantities
        const updatedAllocated2Quantities = [...allocated2Quantities];
        allocatedQuantities.forEach((allocatedItem) => {
          // // Find the index in detailsAM.detailAllocateMaterials by matching so or wbs
          // const index = detailsAM.detailAllocateMaterials.findIndex(function (detail) {
          //   const soSoline = detail.soNumber + "/" + detail.soLine;
          //   if (allocatedItem.so === "null/null") {
          //     return detail.wbs === allocatedItem.wbs;
          //   } else {
          //     return soSoline === allocatedItem.so;
          //   }
          // });

          // // If a matching index is found, update allocated2Quantities at that index
          // if (index !== -1) {
          //   updatedAllocated2Quantities[index] = allocatedItem.currentAllocatedQuantity;
          //   allocatedItem.allocatedQuantityForUpdate = allocatedItem.currentAllocatedQuantity;
          // }

          // Update 1:
          let remainingQuantity = allocatedItem.currentAllocatedQuantity;
          let allocated = 0;
          const indices = [];
          for (let idx = 0; idx < detailsAM.detailAllocateMaterials.length; idx++) {
            const detail = detailsAM.detailAllocateMaterials[idx];
            const soSoline = detail.soNumber + "/" + detail.soLine;
            if (
              (allocatedItem.so === "null/null" && detail.wbs === allocatedItem.wbs) ||
              (allocatedItem.so !== "null/null" && soSoline === allocatedItem.so)
            ) {
              indices.push(idx);
            }
          }

          // If matching indices are found, allocate quantities
          indices.forEach(function (index) {
            if (remainingQuantity > 0) {
              const slConLai = detailsAM.detailAllocateMaterials[index].slConLai;
              const allocatedQuantity = Math.min(remainingQuantity, slConLai);
              updatedAllocated2Quantities[index] = allocatedQuantity;
              remainingQuantity -= allocatedQuantity;
              allocated += allocatedQuantity;
            }
          });

          if (indices.length > 0) {
            allocatedItem.allocatedQuantityForUpdate = allocated;
          }
        });

        // Update the state with the new allocated2Quantities
        setAllocated2Quantities(updatedAllocated2Quantities);

        // Add visible property to each item in detailsAM.detailAllocateMaterials
        detailsAM.detailAllocateMaterials.forEach((detail) => {
          const soSoline = detail.soNumber + "/" + detail.soLine;
          detail.visible = listSOWBS.some(
            (item) => item.wbs === detail.wbs || item.so === soSoline || (item.so === null && item.wbs === null)
          );
        });

        // only display

        setShowTable(true);
      } else {
        toast.warning("SL phân bổ khác với SL cần phân bổ vào LSX vui lòng kiểm tra lại!");
      }
    };

    function handleQuantityAllocation(e: ChangeEvent<HTMLInputElement>, index: number, detail: DetailAllocateMaterial) {
      const value = (e.target.value && Number(e.target.value)) || 0;
      let surplus = value;
      // kiểm tra tồn trơn
      const temp = [...allocatedQuantities];

      let temp4 = [...allocated2Quantities];
      temp4[index] = value;
      const tontron = allocatedQuantities.filter(
        (item) => item.so === null && item.wbs === null && item.currentAllocatedQuantity !== 0
      );

      // Tạo mảng 3 add thêm field quantity
      interface TempSave extends DetailAllocateMaterial {
        quantity: number;
      }
      const temp3: TempSave[] = [];
      for (let j = 0; j < detailsAM!.detailAllocateMaterials.length; j++) {
        temp3.push({ ...detailsAM!.detailAllocateMaterials[j], quantity: temp4[j] });
      }

      allocatedQuantities.forEach((item, i) => {
        if ((item.so !== null && item.so === detail.soNumber) || (item.wbs !== null && item.wbs === detail.wbs)) {
          if (surplus > item.currentAllocatedQuantity) {
            surplus = surplus - item.currentAllocatedQuantity;
            temp[i].allocatedQuantityForUpdate = temp[i].currentAllocatedQuantity;
            setAllocatedQuantities(temp);
          } else {
            // quantity.reduce((total, item) => total + item.allocate, 0)

            let totalTemp = temp3
              .filter((k) => (k.soNumber !== null && k.soNumber === item.so) || (k.wbs !== null && k.wbs === item.wbs))
              .reduce((total, item) => total + item.quantity, 0);

            totalTemp = parseFloat(totalTemp.toFixed(3));

            const t = totalTemp;
            temp[i].allocatedQuantityForUpdate = t;
            surplus = surplus - item.currentAllocatedQuantity;
            setAllocatedQuantities(temp);
          }
        }
      });

      if (surplus > 0 && tontron.length) {
        allocatedQuantities.forEach((item, j) => {
          if (item.so === null && item.wbs === null && item.currentAllocatedQuantity !== 0) {
            const temp2 = [...allocated2Quantities];
            console.log(allocated2Quantities[index]);
            if (temp2[index] === 0) {
              temp[j].allocatedQuantityForUpdate += surplus;
              setAllocatedQuantities(temp);
              temp2[index] = value;
              setAllocated2Quantities([...temp2]);
              console.log("1");
            } else {
              temp[j].allocatedQuantityForUpdate = temp[j].allocatedQuantityForUpdate - temp2[index] + surplus;
              setAllocatedQuantities(temp);
              temp2[index] = value;
              setAllocated2Quantities([...temp2]);
            }
          }
        });
      } else if (surplus <= 0 && !tontron.length) {
        allocatedQuantities.forEach((item, i) => {
          if ((item.so !== null && item.so === detail.soNumber) || (item.wbs !== null && item.wbs === detail.wbs)) {
            let temp2 = [...allocated2Quantities];
            temp2[index] = Math.round((item.currentAllocatedQuantity + surplus) * 10 ** 3) / 10 ** 3;
            setAllocated2Quantities(temp2);
          }
        });
      } else if (surplus > 0 && !tontron.length) {
        allocatedQuantities.forEach((item, i) => {
          if ((item.so !== null && item.so === detail.soNumber) || (item.wbs !== null && item.wbs === detail.wbs)) {
            let temp2 = [...allocated2Quantities];
            temp2[index] = item.currentAllocatedQuantity;
            setAllocated2Quantities(temp2);
          }
        });
      } else if (surplus <= 0 && tontron.length) {
        allocatedQuantities.forEach((item, i) => {
          if (item.so === null && item.wbs === null && item.currentAllocatedQuantity !== 0) {
            let temp2 = [...allocated2Quantities];
            temp[i].allocatedQuantityForUpdate = temp[i].allocatedQuantityForUpdate - temp2[index] + surplus;
            setAllocatedQuantities(temp);
            temp2[index] = surplus;
            setAllocated2Quantities(temp2);
          }
        });
      }
    }

    // kiểm tra so | wbs có trong bản phân bổ hay không
    const disabledAllocationInput = (item: DetailAllocateMaterial) => {
      const temp: any = [...listSOWBS];
      allocatedQuantities.forEach((item, index) => {
        Object.assign(temp[index], { allocate: item.currentAllocatedQuantity });
      });
      const flag = temp
        .filter((i: Allocate) => i.allocate !== 0)
        .filter(
          (s: Allocate) =>
            (s.so === null && s.wbs === null) ||
            (s.so !== null && s.so === item.soNumber) ||
            (s.wbs !== null && s.wbs === item.wbs)
        );
      if (flag.length) return false;
      else return true;
    };

    // useEffect(() => {
    //   console.log(quantity2);
    // }, [quantity2]);

    // New: check edit permission
    const hasEditFunction = () => {
      return pagePermissions.some((permission: any) => permission.functionId === "EDIT");
    };
    //  ==========================LƯU TẠM / LƯU SAP / UPDATE TẠM=======================================
    const handleSaveAllocateMaterials = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: number) => {
      e.preventDefault();
      setLoading(true);
      let flat = true;
      // kiểm tra bảng 1 có bằng bản 2 hay không
      for (let i = 0; i < allocatedQuantities.length; i++) {
        if (allocatedQuantities[i].currentAllocatedQuantity !== allocatedQuantities[i].allocatedQuantityForUpdate) {
          flat = false;
          break;
        }
      }
      if (flat) {
        if (isUpdate) {
          await updateTempAllocate();
        } else {
          await saveAllocate(type);
        }
      } else {
        toast.warning("Số lượng phân bổ không bằng với số lượng được phân bổ!");
      }
      setLoading(false);
    };

    // Luư phân bổ
    const saveAllocate = async (type: number) => {
      let t = detailsAM?.detailAllocateMaterials.map((item, index) => {
        // if (Number(quantity2[index]) > 0) {
        return {
          taskId: item.taskId,
          productCode: productCode,
          allocateAmount: Number(allocated2Quantities[index]) || 0,
          unit: item.unit,
          sloc: detailsAM.sloc,
          batch: detailsAM.batch,
          allocateDate: allocateDate,
          soNumber: item.soNumber ? item.soNumber : null,
          soLine: item.soLine ? item.soLine : null,
          wbs: item.wbs ? item.wbs : null,
          stepCode: detailsAM.stepCode,
        };
        // }
      }) as Array<SaveRequest>;
      // xóa các phần tử có số lượng phân bổ bằng 0
      const dataSave = t.filter((x) => x.allocateAmount !== 0);
      await allocateMaterialsPresenter.saveAllocateMaterials(AllocStore, {
        type: type,
        detailAllocates: dataSave,
        totalAllocateAmount: detailsAM?.allocateAmount,
      });
      if (AllocStore.saveResponse.isSuccess) {
        toast.success(AllocStore.saveResponse.message);
        setPost(true);
        if (type !== 1) {
          navigate("/MES/AllocateMaterials");
        }
      } else {
        toast.error(AllocStore.saveResponse.message);
      }
    };

    // cập nhật bảng lưu tạm
    const updateTempAllocate = async () => {
      let t = detailsAM?.detailAllocateMaterials.map((item, index) => {
        // if (Number(quantity2[index]) > 0) {
        return {
          quantity: allocated2Quantities[index] || 0,
          soNumber: item.soNumber ? item.soNumber : null,
          soLine: item.soLine ? item.soLine : null,
          wbs: item.wbs ? item.wbs : null,
          taskId: item.taskId,
        };
        // }
      }) as Array<UpdateRequest>;
      const dataUpdate = t.filter((x) => x.quantity !== 0);
      await allocateMaterialsPresenter.updateTempAllocate(AllocStore, {
        productCode: productCode,
        batch: detailsAM?.batch,
        stepCode: detailsAM?.stepCode,
        allocateDate: allocateDate,
        detailQuantityEdits: dataUpdate,
      });
      if (AllocStore.updateTempAllocateResponse.isSuccess) {
        toast.success(AllocStore.updateTempAllocateResponse.message);
        setPost(true);
      } else {
        toast.error(AllocStore.updateTempAllocateResponse.message);
      }
    };

    //================================================================================================

    return (
      <div className="w-full py-3 px-5">
        {loading && <LoadingScreen />}
        <div className="flex items-center mb-3">
          <h3 className="text-md font-bold text-gray-800 uppercase">Phân bổ nvl cho lsx</h3>

          <span
            onClick={() => navigate(-1)}
            className="flex items-center text-xs text-primary cursor-pointer font-medium w-fit"
          >
            <IoArrowBackCircle className="mr-2 text-base text-gray-500" />
            Quay lại
          </span>
        </div>
        <div className="w-full bg-white py-3 flex rounded mb-2 border border-neutral-300">
          <form className="w-full px-8  py-2 lg:grid lg:grid-cols-2 flex flex-col">
            {/* Ngày phân bổ*/}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end">Ngày phân bổ</label>
              <input
                type="text"
                disabled
                // defaultValue={allocateDate}
                defaultValue={moment(allocateDate).format("DD/MM/YYYY")}
                className="xl:xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
              />
            </div>
            {/* Số lô */}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end">Số lô</label>
              <input
                type="text"
                disabled
                defaultValue={detailsAM?.batch}
                className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
              />
            </div>

            {/* Plant */}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end">Plant</label>
              <input
                type="text"
                disabled
                defaultValue={detailsAM?.plant}
                className="xl:xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
              />
            </div>
            {/* Sloc */}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end">Sloc</label>
              <Tooltip title={`${detailsAM?.sloc} | ${detailsAM?.slocName}`}>
                <input
                  type="text"
                  disabled
                  value={`${detailsAM?.sloc} | ${detailsAM?.slocName}`}
                  className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
                />
              </Tooltip>
            </div>

            {/* Nguyên vật liệu*/}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end ">NVl</label>
              <div className="grid xl:grid-cols-4 xl:col-span-4 gap-3">
                <input
                  type="text"
                  disabled
                  defaultValue={detailsAM?.productCode}
                  className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
                />
                <Tooltip title={detailsAM?.productName}>
                  <input
                    type="text"
                    disabled
                    defaultValue={detailsAM?.productName}
                    className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
                  />
                </Tooltip>
              </div>
            </div>
            {/* Số lượng phân bổ */}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end">Số lượng phân bổ</label>
              <input
                type="text"
                disabled
                defaultValue={detailsAM?.allocateAmount}
                className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
              />
            </div>
            {/* Công đoạn*/}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end ">Công đoạn</label>
              <div className="grid xl:grid-cols-4 xl:col-span-4 gap-3">
                <input
                  type="text"
                  disabled
                  defaultValue={detailsAM?.stepCode}
                  className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
                />
                <Tooltip title={detailsAM?.stepName}>
                  <input
                    type="text"
                    disabled
                    defaultValue={detailsAM?.stepName}
                    className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
                  />
                </Tooltip>
              </div>
            </div>

            {/* Đơn vị tính */}
            <div className="w-full flex flex-col mb-3 sm:items-center sm:grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-0 lg:gap-3">
              <label className="font-medium text-default text-gray-700 lg:text-end">Đơn vị tính</label>
              <input
                type="text"
                disabled
                defaultValue={detailsAM?.unit}
                className="xl:col-span-2 text-gray-700 border border-gray-300 px-3 py-1.5 text-default disabled:bg-gray-200"
              />
            </div>
          </form>
        </div>

        {/* =========== Table Work Order ===============*/}
        <div className={`my-3 px-4 pt-2 w-full bg-white py-5 rounded border border-neutral-300`}>
          <div className="overflow-x-auto mb-2 pb-1 xl:flex xl:justify-between xl:items-center">
            {/* Bảng NVL tồn kho SAP */}
            <div>
              <h3 className="text-default font-medium">NVL tồn kho SAP</h3>
              <TableContainer
                component={Paper}
                className="w-full xl:!w-[600px] table-auto overflow-y-hidden text-default"
              >
                <Table aria-label="simple table" sx={Style.borderInherit}>
                  <TableHead className="font-medium text-gray-700 text-default bg-gray-100">
                    <TableRow>
                      {column?.map((item, index) => {
                        if (item.table1) {
                          return (
                            <TableCell
                              sx={[Style.MuiTableCellRoot]}
                              key={index}
                              align={`${item.align === "text-center" ? "center" : "inherit"}`}
                              scope="col"
                              className={`py-2 px-2 border  text-left bg-primary ${item.align} whitespace-nowrap`}
                            >
                              {item.columnName}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listSOWBS.length ? (
                      listSOWBS.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell
                            className="border whitespace-nowrap !text-center w-[60px]"
                            sx={[Style.MuiTableCellRootTd]}
                          >
                            {index + 1}
                          </TableCell>
                          {/* So/So line | Wbs */}
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {item.so}
                            {item.soLine && `/${item.soLine}`}
                            {item.wbs}
                            {!item.so && !item.wbs ? "Tồn trơn" : ""}
                          </TableCell>
                          {/* <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                        {item.wbs}
                      </TableCell> */}
                          {/* Số lượng tồn kho */}
                          <TableCell className="border whitespace-nowrap !text-right" sx={[Style.MuiTableCellRootTd]}>
                            {formatNumber(item.quantity, 3)}
                          </TableCell>

                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            <input
                              type="number"
                              min="0"
                              max={item.quantity}
                              step="0.1"
                              value={allocatedQuantities.length && allocatedQuantities[index].currentAllocatedQuantity}
                              onChange={(e) => handleChangQuantity(e, index)}
                              className={`w-full border outline-none px-1.5 py-0.25 text-end focus-visible:outline-none  caret-blue-600 disabled:bg-gray-200 ${
                                allocatedQuantities[index].currentAllocatedQuantity <
                                allocatedQuantities[index].allocatedQuantityForUpdate
                                  ? "border-red-500 focus:border-red-600"
                                  : "focus:border-blue-500"
                              }`}
                            />
                          </TableCell>
                          {/* Đơn vị tính */}
                          <TableCell
                            className="border whitespace-nowrap !text-center w-[150px]"
                            sx={[Style.MuiTableCellRootTd]}
                          >
                            {item.unit}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={15} sx={[Style.MuiTableCellRootTd]} className="border !text-center">
                          Không tìm thấy dòng nào phù hợp
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={4} sx={[Style.MuiTableCellRootTd]} className="border !text-right">
                        <span
                          className={`text-default ${
                            detailsAM &&
                            allocatedQuantities.length &&
                            allocatedQuantities.reduce((total, item) => total + item.currentAllocatedQuantity, 0) >
                              detailsAM.allocateAmount
                              ? "text-red-600"
                              : ""
                          }`}
                        >
                          <span>Tổng cộng: </span>
                          {formatNumber(
                            allocatedQuantities.length
                              ? allocatedQuantities.reduce((total, item) => total + item.currentAllocatedQuantity, 0)
                              : 0,
                            3
                          )}
                        </span>
                      </TableCell>
                      <TableCell sx={[Style.MuiTableCellRootTd]} className="border whitespace-nowrap">
                        <button
                          onClick={handlePhanBoTrenXuong}
                          className="text-default text-white bg-primary px-5 py-0.5 rounded-base border-gray-300 border mx-auto flex items-center justify-center hover:bg-primary/80 disabled:bg-gray-400"
                        >
                          Phân bổ
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div>
              <HiArrowNarrowRight className="rotate-90 my-3 xl:my-0 xl:rotate-0 mx-auto" />
            </div>
            <div>
              <h3 className="text-default font-medium">NVL phân bổ</h3>
              <TableContainer
                component={Paper}
                className="w-full xl:!w-[600px] table-auto overflow-y-hidden text-default"
              >
                <Table aria-label="simple table" sx={Style.borderInherit}>
                  <TableHead className="font-medium text-gray-700 text-default bg-gray-100">
                    <TableRow>
                      {column?.map((item, index) => {
                        if (item.table1) {
                          return (
                            <TableCell
                              sx={[Style.MuiTableCellRoot]}
                              key={index}
                              align={`${item.align === "text-center" ? "center" : "inherit"}`}
                              scope="col"
                              className={`py-2 px-2 border  text-left bg-primary ${item.align} whitespace-nowrap`}
                            >
                              {item.columnName}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listSOWBS.length ? (
                      listSOWBS.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell
                            className="border whitespace-nowrap !text-center w-[60px]"
                            sx={[Style.MuiTableCellRootTd]}
                          >
                            {index + 1}
                          </TableCell>
                          {/* So/So line | Wbs */}
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {item.so}
                            {item.soLine && `/${item.soLine}`}
                            {item.wbs}
                            {!item.so && !item.wbs ? "Tồn trơn" : ""}
                          </TableCell>
                          {/* <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                        {item.wbs}
                      </TableCell> */}
                          {/* Số lượng tồn kho */}
                          <TableCell className="border whitespace-nowrap !text-right" sx={[Style.MuiTableCellRootTd]}>
                            {formatNumber(item.quantity, 3)}
                          </TableCell>

                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            <input
                              readOnly
                              disabled
                              value={
                                allocatedQuantities.length &&
                                formatNumber(allocatedQuantities[index].allocatedQuantityForUpdate, 3)
                              }
                              className="w-full border outline-none px-1.5 py-0.25 text-end focus-visible:outline-none focus:border-blue-500 caret-blue-600 disabled:bg-gray-200"
                            />
                          </TableCell>
                          {/* Đơn vị tính */}
                          <TableCell
                            className="border whitespace-nowrap !text-center w-[150px]"
                            sx={[Style.MuiTableCellRootTd]}
                          >
                            {item.unit}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={15} sx={[Style.MuiTableCellRootTd]} className="border !text-center">
                          Không tìm thấy dòng nào phù hợp
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={4} sx={[Style.MuiTableCellRootTd]} className="border !text-right">
                        <span
                          className={`text-default ${
                            detailsAM &&
                            allocatedQuantities.length &&
                            allocatedQuantities.reduce((total, item) => total + item.allocatedQuantityForUpdate, 0) >
                              detailsAM.allocateAmount
                              ? "text-red-600"
                              : ""
                          }`}
                        >
                          <span>Tổng cộng: </span>
                          {formatNumber(
                            allocatedQuantities.length
                              ? allocatedQuantities.reduce((total, item) => total + item.allocatedQuantityForUpdate, 0)
                              : 0,
                            3
                          )}
                        </span>
                      </TableCell>
                      <TableCell sx={[Style.MuiTableCellRootTd]} className="border whitespace-nowrap"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {showTable ? (
            <>
              <h3 className="text-default font-medium mt-3">Các LSX ghi nhận trong ngày</h3>
              <TableContainer component={Paper} className="w-full table-auto overflow-y-hidden text-default">
                <Table aria-label="simple table" sx={Style.borderInherit}>
                  <TableHead className="font-medium text-gray-700 text-default bg-gray-100">
                    <TableRow>
                      {column?.map((item, index) => {
                        if (item.visible) {
                          return (
                            <TableCell
                              sx={[Style.MuiTableCellRoot]}
                              key={index}
                              align={`${item.align === "text-center" ? "center" : "inherit"}`}
                              scope="col"
                              className={`py-2 px-2 border  text-left bg-primary ${item.align} whitespace-nowrap`}
                            >
                              {item.columnName}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* <h1>Table 2</h1> */}
                    {detailsAM && detailsAM.detailAllocateMaterials.length ? (
                      detailsAM.detailAllocateMaterials
                        // .filter((item) => item.visible)
                        .map((item, index) => {
                          const visibleIndex =
                            detailsAM.detailAllocateMaterials.filter((i, idx) => i.visible && idx < index).length + 1;

                          return (
                            // if visible = false then hide this row
                            // <TableRow key={index}>
                            // Update 1: hide rows with visible = false
                            <TableRow key={index} style={{ display: item.visible ? "table-row" : "none" }}>
                              <TableCell
                                className="border whitespace-nowrap !text-center w-[60px]"
                                sx={[Style.MuiTableCellRootTd]}
                              >
                                {/* {index + 1} */}
                                {item.visible ? visibleIndex : ""}
                              </TableCell>
                              {/* Lệnh sản xuất */}
                              <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                                {item.lsx}
                              </TableCell>
                              {/*So/So line | wbs */}
                              <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                                {item.soNumber ? item.soNumber : ""}
                                {`${item.soLine ? "/" + item.soLine : ""}`}
                                {item.wbs ? item.wbs : ""}
                              </TableCell>
                              {/* Sản phẩm */}
                              <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                                {item.productCode} | {item.productName}
                              </TableCell>
                              {/* Số lượng LSX */}
                              <TableCell
                                className="border whitespace-nowrap !text-right"
                                sx={[Style.MuiTableCellRootTd]}
                              >
                                {formatNumber(item.lsxQty, 0)}
                              </TableCell>
                              {/* Số lượng yêu cầu theo lệnh */}
                              <TableCell
                                className="border whitespace-nowrap !text-right"
                                sx={[Style.MuiTableCellRootTd]}
                              >
                                {formatNumber(item.slConLai, 3)}
                              </TableCell>
                              {/* Số lượng đã phân bổ */}
                              <TableCell
                                className="border whitespace-nowrap !text-right"
                                sx={[Style.MuiTableCellRootTd]}
                              >
                                {formatNumber(item.sldapb, 3)}
                              </TableCell>
                              {/* Số lượng còn lại  */}
                              <TableCell
                                className="border whitespace-nowrap !text-right"
                                sx={[Style.MuiTableCellRootTd]}
                              >
                                {formatNumber(item.slConLai, 3)}
                              </TableCell>
                              {/* Số lượng phân bổ */}
                              <TableCell className="border" sx={[Style.MuiTableCellRootTd]}>
                                <input
                                  type="number"
                                  min="0"
                                  step="0.1"
                                  disabled={disabledAllocationInput(item)}
                                  value={allocated2Quantities[index]}
                                  onChange={(e) => handleQuantityAllocation(e, index, item)}
                                  className="border w-full outline-none px-1.5 py-0.25 text-end focus-visible:outline-none focus:border-blue-500 caret-blue-600 disabled:bg-gray-200"
                                />
                              </TableCell>
                              {/* Đơn vị tính  */}
                              <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                                {item.unit}
                              </TableCell>
                              {/* % Tổng quan */}
                              <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                                {item.summary}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={15} sx={[Style.MuiTableCellRootTd]} className="border !text-center">
                          Không tìm thấy dòng nào phù hợp
                        </TableCell>
                      </TableRow>
                    )}
                    {/* // TODO: check permission client AND SERVER */}

                    {detailsAM && detailsAM.detailAllocateMaterials.length && hasEditFunction() ? (
                      <TableRow>
                        <TableCell colSpan={9} sx={[Style.MuiTableCellRootTd]} className="border !text-right">
                          <span className="text-default">
                            <span>Tổng cộng:</span>
                            {formatNumber(
                              allocated2Quantities.length
                                ? allocated2Quantities.reduce((total, item) => total + item)
                                : 0,
                              3
                            )}
                          </span>
                        </TableCell>
                        <TableCell colSpan={2} sx={[Style.MuiTableCellRootTd]} className="border whitespace-nowrap">
                          <div className="flex gap-1">
                            {/* Type == 1 là lưu tạm */}
                            <button
                              type="button"
                              onClick={(e) => handleSaveAllocateMaterials(e, 1)}
                              className="text-default text-white bg-primary px-5 py-0.5 rounded-base border-gray-300 border mx-auto flex items-center justify-center hover:bg-primary/80 disabled:bg-gray-400"
                            >
                              {/* {isUpdate ? 'Sửa' : 'Lưu tạm'} */}
                              Lưu tạm
                            </button>
                            {/* Type != 1 là lưu SAP */}
                            <button
                              disabled={!isPost}
                              onClick={() => saveAllocate(0)}
                              className="text-default text-white bg-primary px-5 py-0.5 rounded-base border-gray-300 border mx-auto flex items-center justify-center hover:bg-primary/80 disabled:bg-gray-400"
                            >
                              Post SAP
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
          <div className="text-default">
            <h3>Lưu ý:</h3>
            <p>+ Tồn trơn có thể phân bổ cho tất cả các LSX</p>
            <p>+ Tồn E (theo SO | WBS) cần phân bổ đúng LSX tương ứng</p>
          </div>
        </div>
      </div>
    );
  });
}
