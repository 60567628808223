import { observable, runInAction } from 'mobx';
import { Company, ICompanyServices, saleORG } from 'services/company_services';

export type CompanyStore = {
  companies: Array<Company>;
  saleOrg: Array<saleORG>;
};

export interface ICompanyPresenter {
  createStore(): CompanyStore;
  geListtCompany(store: CompanyStore, username: string): Promise<void>;
  getListSaleORG(
    store: CompanyStore,
    username: string,
    companyCode: string
  ): Promise<void>;
}

export function createCompanypresenter({
  companyServices,
}: {
  companyServices: ICompanyServices;
}): ICompanyPresenter {
  return {
    createStore: (): CompanyStore => observable({ companies: [], saleOrg: [] }),
    geListtCompany: async (store: CompanyStore, username: string) => {
      try {
        const companies = await companyServices.getListCompany(username);

        runInAction(() => {
          store.companies = companies;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getListSaleORG: async (
      store: CompanyStore,
      username: string,
      companyCode: string
    ) => {
      try {
        const saleOrg = await companyServices.getListSaleORG(
          username,
          companyCode
        );
        runInAction(() => {
          store.saleOrg = saleOrg;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
