import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "utils/formatHelper";
import { FaFileExport } from "react-icons/fa";
import * as XLSX from 'xlsx';

// components
import { HiSearch } from "react-icons/hi";
import { MdEdit } from "react-icons/md";
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import LoadingScreen from "base/ui/components/LoadingScreen/loadingScreen";

// Store
import {
  AllocStore,
  allocateMaterialsPresenter,
  companyPresenter,
  companyStore,
  commonPresenter,
  commonStore,
} from "stores/root_store";

// Models
import { AMDetailRequest, AllocateMaterialsModel } from "services/allocateMaterials_services";
import { Company } from "services/company_services";
import { CommonSlocModel } from "services/common_servieces";
import { TbLoaderQuarter } from "react-icons/tb";
import React from "react";

// Add this type definition
type ExportDataType = (string | number)[][];

export function createAllocateMaterials() {
  const column = [
    {
      columnName: "STT",
      align: "text-center",
      visible: true,
    },
    { columnName: "Mã SP", align: "text-center", visible: true },

    { columnName: "Tên SP", align: "text-center", visible: true },

    {
      columnName: "Số lô",
      align: "text-center",
      visible: true,
    },

    { columnName: "Sloc", align: "text-center", visible: true },
    { columnName: "Plant", align: "text-center", visible: true },
    { columnName: "Công đoạn", align: "text-center", visible: true },

    {
      // columnName: 'SL xuất sử dụng trong ngày',
      columnName: (
        <>
          <div>SL xuất sử dụng</div>
          <div>trong ngày</div>
        </>
      ),
      align: "text-center",
      visible: true,
    },
    {
      // columnName: 'SL cần phân bổ vào LSX',
      columnName: (
        <>
          <div>SL cần phân bổ</div>
          <div>vào LSX</div>
        </>
      ),
      align: "text-center",
      visible: true,
    },
    {
      columnName: "ĐVT",
      align: "text-center",
      visible: true,
    },
    {
      columnName: "Trạng thái",
      align: "text-center",
      visible: true,
    },
    {
      columnName: "Chức năng",
      align: "text-center",
      visible: true,
    },
    {
      columnName: "Họ tên",
      align: "text-center",
      visible: true,
    },
    // wbs
    {
      columnName: "WBS",
      align: "text-left",
      visible: true,
    },
  ];

  const Style = {
    MuiTableCellRoot: {
      fontSize: "12px",
      fontWeight: 700,
      padding: "5px 5px",
      lineHeight: "20px",
      color: "white",
    },
    borderInherit: {
      borderCollapse: "inherit",
    },
    MuiTableCellRootTd: {
      fontSize: "13px",
      padding: "5px",
      lineHeight: "20px",
      borderBottom: "unset",
    },
    ".MuiOutlinedInput-root": {
      padding: 0,
    },
    ".MuiOutlinedInput-input": {
      fontSize: "13px",
    },
    ".MuiOutlinedInput-notchedOutline": { borderRadius: 0 },
  };
  return observer(() => {
    // ======================Navigate===============================
    const navigate = useNavigate();
    const user_session = JSON.parse(localStorage.getItem("user_session") || "{}");
    // ===========================STATE==========================
    const [status, setStatus] = useState(AllocStore.dataSearchAllocate.status);
    const [allocateDate, setAllocateDate] = useState(AllocStore.dataSearchAllocate.allocateDate);
    const [loading, setLoading] = useState(true);
    const [allocateMaterialsList, setAllocateMaterialsList] = useState<AllocateMaterialsModel[]>();
    const [sloc, setSloc] = useState({
      slocCode: AllocStore.dataSearchAllocate.sloc,
      keyWord: "",
    });
    const [listSloc, setListSloc] = useState<CommonSlocModel[]>([]);
    const [listCompany, setListCompany] = useState<Company[]>([]);
    const [companyCode, setCompanyCode] = useState(user_session.companyCode || "");
    const [exportData, setExportData] = useState<ExportDataType>([]);
    // ===========================FUNCTION==========================
    const handleChangeStatus = (event: SelectChangeEvent) => {
      setStatus(event.target.value);
    };

    // USE EFFECT ==================================================
    // Lấy danh sách plant
    const getListPlants = async () => {
      await companyPresenter.geListtCompany(companyStore, user_session.userName);
      setListCompany(companyStore.companies);
      // setCompanyCode(companyStore.companies[0].companyCode);
    };

    // lấy common sloc
    const getSloc = async () => {
      await commonPresenter.getSloc(commonStore, sloc.keyWord, user_session.companyCode);
      if (commonStore.slocResponse.isSuccess) {
        setListSloc(commonStore.slocResponse.data);
      }
    };

    const getListAllocateMaterials = async () => {
      await allocateMaterialsPresenter.getListAllocateMaterials(AllocStore, {
        status: JSON.parse(status),
        allocateDate: allocateDate,
        plant: companyCode,
        sloc: sloc.slocCode,
      });
      setAllocateMaterialsList(AllocStore.AllocateMaterialsResponse.data);
      // exportData
      prepareDataForExport(AllocStore.AllocateMaterialsResponse.data);
    };

    useEffect(() => {
      const getData = async () => {
        await getListAllocateMaterials();
        await getListPlants();
        await getSloc();
        setLoading(false);
      };
      getData();
    }, []);

    // Xử lý tìm kiếm
    const handleSearch = async () => {
      setLoading(true);
      await getListAllocateMaterials();
      await allocateMaterialsPresenter.setDataSearchAllocate(AllocStore, {
        status: status,
        allocateDate: allocateDate,
        plant: companyCode,
        sloc: sloc.slocCode,
      });
      setLoading(false);
    };

    // xử lý tìm sloc
    const handleChangeSloc = (e: React.SyntheticEvent<Element, Event>, value: CommonSlocModel | null) => {
      if (value) {
        setSloc({ ...sloc, slocCode: value.sloc });
      } else {
        setSloc({ keyWord: "", slocCode: "" });
      }
    };
    // lấy keyWord sloc
    const handleChangeKeyWorSloc = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSloc({ keyWord: e.target.value, slocCode: "" });
    };

    useEffect(() => {
      getSloc();
    }, [sloc.keyWord]);

    // Update the prepareDataForExport function to return the correct type
    const prepareDataForExport = async (data: AllocateMaterialsModel[]) => {
      const headers = [
        "STT",
        "Mã SP",
        "Tên SP",
        "Số lô",
        "Sloc",
        "Plant",
        "Công đoạn",
        "SL xuất sử dụng trong ngày",
        "SL cần phân bổ vào LSX",
        "ĐVT",
        "Trạng thái",
        "Họ tên",
        "WBS",
        "SL phân bổ WBS"
      ];

      const dataTemp = data.flatMap((item) => {
        const wbsEntries = item.soWbs.split(",").map((wbs) => wbs.trim());
        return wbsEntries.map((wbsEntry) => {
          const [wbs, allocateAmountWbs] = wbsEntry.split("|").map(s => s.trim());
          return [
            item.stt,
            item.productCode,
            item.productName,
            item.batch,
            `${item.sloc} | ${item.slocName}`,
            item.plant,
            `${item.stepCode} | ${item.stepName}`,
            item.exportAmountInDay,
            item.allocateAmount,
            item.unit,
            item.statusPB,
            item.fullName,
            wbs,
            allocateAmountWbs || ''
          ];
        });
      });

      setExportData([headers, ...dataTemp]);
    };

    const exportToExcel = () => {
      if (exportData.length === 0) {
        alert("Không có dữ liệu");
        return;
      }

      const ws = XLSX.utils.aoa_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "AllocateMaterials");
      
      // Generate buffer
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      
      // Save to file
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileName = `allocate_materials_${allocateDate}.xlsx`;
      
      // Use a more universal approach
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };

    return (
      <div className="w-full py-3 px-5">
        {loading && <LoadingScreen />}
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-md font-bold text-gray-800 mb-2 uppercase">PHÂN BỔ NVL CHO LSX</h3>
        </div>
        {/* Form nhập tìm kiếm */}
        <div className="w-full bg-white py-3 flex rounded mb-2 border border-neutral-300">
          <form className="w-full px-8  py-2 lg:grid lg:grid-cols-2 flex flex-col">
            {/* Ngày phân bổ*/}
            <div className="w-full sm:flex-row mb-3 sm:items-center grid grid-cols-3">
              <label
                htmlFor="allocateDate"
                className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end "
              >
                Ngày phân bổ
              </label>
              <input
                type="date"
                id="allocateDate"
                value={allocateDate}
                onChange={(e) => setAllocateDate(e.target.value)}
                className="col-span-2 text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default focus-visible:outline-none focus:border-blue-500 caret-blue-600"
              />
            </div>

            <div className="w-full sm:flex-row mb-3 sm:items-center grid grid-cols-3">
              <label htmlFor="status" className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                Trạng thái
              </label>
              <FormControl className="col-span-2">
                <Select
                  id="status"
                  className="text-gray-700 text-default hover:ring-0 hover:outline-none hover:border-gray-300 !rounded-none border-gray-300 sm:ml-5 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                  displayEmpty
                  sx={{ height: 35 }}
                  value={status}
                  onChange={handleChangeStatus}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="null">
                    <span className="text-default">-- Tất cả --</span>
                  </MenuItem>
                  <MenuItem value="3">
                    <span className="text-default">Đã phân bổ</span>
                  </MenuItem>
                  <MenuItem value="2">
                    <span className="text-default">Lưu bảng tạm</span>
                  </MenuItem>
                  <MenuItem value="1">
                    <span className="text-default">Chưa phân bổ</span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="w-full sm:flex-row mb-3 sm:items-center grid grid-cols-3">
              <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">Plant</label>
              <FormControl className="col-span-2">
                <Select
                  id="status"
                  className="text-gray-700 text-default hover:ring-0 hover:outline-none hover:border-gray-300 !rounded-none border-gray-300 sm:ml-5 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                  displayEmpty
                  onChange={(e) => setCompanyCode(e.target.value)}
                  value={companyCode}
                  sx={{ height: 35 }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {listCompany.length
                    ? listCompany.map((company, index) => (
                        <MenuItem value={company.companyCode} key={index}>
                          <span className="text-default">{company.companyName}</span>
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            {/* Sloc */}
            <div className="w-full sm:flex-row mb-3 sm:items-center grid grid-cols-3">
              <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">Sloc</label>
              <Autocomplete
                className="col-span-2 text-gray-700 text-default hover:ring-0 hover:outline-none hover:border-border_input !rounded-none border-border_input sm:ml-5 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                id="disabled-options-demo"
                options={listSloc}
                onChange={handleChangeSloc}
                sx={Style}
                classes={{ listbox: "text-default", input: "!text-gray-700", noOptions: "text-default" }}
                filterOptions={(options, state) => options}
                getOptionLabel={(option) => option.slocDisplay}
                renderInput={(params) => <TextField onChange={handleChangeKeyWorSloc} {...params} />}
              />
            </div>

            {/* button tìm kiếm */}
            <div className="w-full flex justify-center col-span-2 gap-2">
              <button
                type="button"
                onClick={handleSearch}
                className={`flex items-center mt-1 text-white bg-blue-700 rounded text-default px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600`}
              >
                {loading ? (
                  <>
                    <TbLoaderQuarter className="animate-spin" />
                    Loading...
                  </>
                ) : (
                  <>
                    <HiSearch className="font-bold text-lg" />
                    Tìm kiếm
                  </>
                )}
              </button>
            </div>
          </form>
        </div>

        {/* =========== Table Work Order ===============*/}

        <div className={`w-full bg-white py-3 px-5 rounded border border-neutral-300`}>
          <div className="py-1 overflow-x-auto">
            <div className="flex justify-start mb-2">
              <button
                onClick={exportToExcel}
                className={`inline-flex items-center text-white rounded text-default px-2.5 py-1 text-center focus:outline-none ${
                  !exportData || exportData.length === 0
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-green-600 hover:bg-green-500"
                }`}
                disabled={!exportData || exportData.length === 0}
              >
                Export
              </button>
            </div>
            <TableContainer
              component={Paper}
              className="w-full table-auto overflow-y-hidden space-collapse text-default"
            >
              <Table aria-label="simple table" sx={Style.borderInherit}>
                <TableHead className="font-medium text-gray-700  text-default bg-gray-100">
                  <TableRow>
                    {column?.map((item, index) => {
                      if (item.visible) {
                        return (
                          <TableCell
                            sx={[Style.MuiTableCellRoot]}
                            key={index}
                            align={`${item.align === "text-center" ? "center" : "inherit"}`}
                            scope="col"
                            className={`${item.visible ? "" : "hidden"} py-2 px-2 border  text-left bg-primary ${
                              item.align
                            } whitespace-nowrap`}
                          >
                            {item.columnName}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allocateMaterialsList && allocateMaterialsList.length !== 0 ? (
                    allocateMaterialsList?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          {/* STT */}
                          <TableCell className="border !text-center" sx={[Style.MuiTableCellRootTd]}>
                            {item.stt}
                          </TableCell>
                          {/* Mã SP */}
                          <TableCell className="border" sx={[Style.MuiTableCellRootTd]}>
                            {item.productCode}
                          </TableCell>
                          {/* Tên SP */}
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {item.productName}
                          </TableCell>
                          {/* Số lô */}
                          <TableCell className="border" sx={[Style.MuiTableCellRootTd]}>
                            {item.batch}
                          </TableCell>
                          {/* Sloc */}
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {`${item.sloc} | ${item.slocName}`}
                          </TableCell>
                          {/* plant */}
                          <TableCell className="border" sx={[Style.MuiTableCellRootTd]}>
                            {item.plant}
                          </TableCell>
                          {/* Công đoạn */}
                          <TableCell
                            className="border whitespace-nowrap"
                            sx={[Style.MuiTableCellRootTd]}
                          >{`${item.stepCode} | ${item.stepName}`}</TableCell>

                          {/* SL xuất sử dụng trong ngày */}
                          <TableCell className="border !text-right" sx={[Style.MuiTableCellRootTd]}>
                            {formatNumber(item.exportAmountInDay, 3)}
                            {/* {item.exportAmountInDay} */}
                          </TableCell>
                          {/* SL cần phân bổ vào LSX */}
                          <TableCell className="border !text-right" sx={[Style.MuiTableCellRootTd]}>
                            {formatNumber(item.allocateAmount, 3)}
                            {/* {item.allocateAmount} */}
                          </TableCell>
                          {/* đơn vị tính */}
                          <TableCell className="border" sx={[Style.MuiTableCellRootTd]}>
                            {item.unit}
                          </TableCell>
                          {/* trạng thái */}
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {item.statusPB}
                          </TableCell>
                          {/* chức năng */}
                          <TableCell
                            sx={[Style.MuiTableCellRootTd]}
                            className="border whitespace-nowrap flex justify-center"
                          >
                            <div className="flex gap-1">
                              <button
                                disabled={item.status !== 1}
                                onClick={() =>
                                  navigate("Edit", {
                                    state: {
                                      productCode: item.productCode,
                                      batch: item.batch,
                                      sloc: item.sloc,
                                      plant: item.plant,
                                      stepCode: item.stepCode,
                                      allocateDate: allocateDate,
                                      isUpdate: false,
                                    } as AMDetailRequest,
                                  })
                                }
                                className="text-default text-white bg-primary  px-2.5 py-0.25 rounded-base border-gray-300 border flex gap-1 items-center justify-center disabled:bg-gray-500"
                              >
                                <MdEdit /> Phân bổ
                              </button>
                              {item.status === 2 && (
                                <button
                                  onClick={() =>
                                    navigate("Edit", {
                                      state: {
                                        productCode: item.productCode,
                                        batch: item.batch,
                                        sloc: item.sloc,
                                        plant: item.plant,
                                        stepCode: item.stepCode,
                                        allocateDate: allocateDate,
                                        isUpdate: true,
                                      } as AMDetailRequest,
                                    })
                                  }
                                  className="text-default text-white bg-primary  px-2.5 py-0.25 rounded-base border-gray-300 border flex gap-1 items-center justify-center disabled:bg-gray-500"
                                >
                                  <MdEdit /> Sửa
                                </button>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {item.fullName}
                          </TableCell>
                          <TableCell className="border whitespace-nowrap" sx={[Style.MuiTableCellRootTd]}>
                            {item.soWbs.split(",").map((wbs, index) => (
                              <React.Fragment key={index}>
                                {wbs.trim()}
                                {index < item.soWbs.split(",").length - 1 && <br />}
                              </React.Fragment>
                            ))}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} className="border !text-center py-1" sx={[Style.MuiTableCellRootTd]}>
                        Không tìm thấy dòng nào phù hợp
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* <span className='block my-4 text-default'>
            Đang xem 0 trong tổng số 0 mục
          </span> */}
        </div>
      </div>
    );
  });
}
