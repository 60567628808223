import { observable, runInAction } from 'mobx';

import { User, WebPermission } from 'services/user_services';
import { IUserServices } from 'services/user_services';
import { clearUserSession, setUserSession } from '../utils/localStorageHelper';
import { createBrowserHistory } from 'history';

type UserSession = {
  userName: string;
  role: string;
  token: string;
  refreshToken: string;
  webPermission: WebPermission;
};

export const history = createBrowserHistory();
export type UserStore = {
  isVendor: boolean;
  userRespone: {
    data: User | undefined;
    isSuccess: boolean;
    message: string;
  };
  changePasswordResponse: {
    isSuccess: boolean;
    message: string;
  };
};

export interface IUserPresenter {
  createStore(): UserStore;
  login(store: UserStore, data: object): Promise<boolean>;
  checkAccount(store: UserStore, userName: string): Promise<void>;
  changePassword(store: UserStore, data: object): Promise<void>;
  signOut(): Promise<void>;
}

export function createUserPresenter({
  userServices,
}: {
  userServices: IUserServices;
}): IUserPresenter {
  return {
    createStore: (): UserStore =>
      observable({
        userRespone: {
          data: undefined,
          isSuccess: false,
          message: '',
        },
        isVendor: false,
        changePasswordResponse: {
          isSuccess: false,
          message: '',
        },
      }),
    login: async (store: UserStore, data: object) => {
      try {
        const result = await userServices.login(data);
        runInAction(() => {
          store.userRespone.data = result.data;
          store.userRespone.message = result.message;
          store.userRespone.isSuccess = result.isSuccess;
          // kiểm tra nếu có data thì lưu thông tin vào localStore
          if (result.data) {
            const userSession = {
              // accountId: result.accountId,
              userName: result.data.userName,
              fullName: result.data.fullName,
              companyCode: result.data.companyCode,
              // companyId: result.data.companyId,
              // companyName: result.data.companyName,
              expiredTime: result.data.expiredTime,
              // saleOrg: result.data.saleOrg,
              // saleOrgName: result.data.saleOrgName,
              // validaty: result.data.validaty,
              // roles: result.data.roles,
              role: result.data.role,
              token: result.data.token,
              refreshToken: result.data.refreshToken,
              webPermission: result.data.webPermission,
            };
            setUserSession(userSession);
          }
        });
        // history.push('/');
        // window.location.reload();
        if (result) {
          return true;
        } else {
          return false;
        }
      } catch {
        // error
        return false;
      }
    },
    checkAccount: async (store: UserStore, userName: string) => {
      try {
        const result = await userServices.checkAccount(userName);
        runInAction(() => {
          store.isVendor = result;
        });
      } catch (e) {}
    },
    changePassword: async (store: UserStore, data: object) => {
      try {
        const result = await userServices.changePassword(data);
        runInAction(() => {
          store.changePasswordResponse.isSuccess = result.isSuccess;
          store.changePasswordResponse.message = result.message;
        });
      } catch (e) {
        console.log(e);
      }
    },
    signOut: async () => {
      try {
        clearUserSession();
      } catch (e) {}
    },
  };
}
