import React from 'react';
import { BiCopyright } from 'react-icons/bi';

export const Footer = React.memo(() => {
    return (
        <footer className="bg-white border-t border-gray-300 flex justify-between py-1.5 text-sm text-gray-700 px-3">
            <span className="flex items-center font-medium">
                Copyright <BiCopyright className="mx-1" /> 2023
                <a href="https://truongthanh.com" className="ml-1 text-sky-500">
                TTF
                </a>
                <p className="font-normal">. All rights reserved.</p>
            </span>
            <span>v{process.env.REACT_APP_VERSION}</span>
        </footer>
    );
});
