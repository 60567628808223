import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/logo.png';
import { HiMenu } from 'react-icons/hi';
import { User } from 'services/user_services';
import MenuItem from 'base/ui/layout/sideBar/menuItem';
import SearchBarInSideBar from './searchBar/searchBar';
export const Sidebar = React.memo(() => {
  const [open, setOpen] = useState(false);
  const data = JSON.parse(localStorage.getItem('user_session') || '{}') as User;
  const parentMenu = data.webPermission?.menuModel;
  const childMenu = data.webPermission?.pageModel;
  const handleShowMenu = () => {
    setOpen(!open);
  };

  return (
    <div
      className={`absolute h-full sm:h-auto sm:relative bg-sidebar_bg min-h-screen pt-1.5 ${
        open ? 'sm:block w-72' : 'w-0 sm:w-14'
      } duration-300`}
    >
      <HiMenu
        className="absolute text-white text-lg -right-9 top-4 cursor-pointer hidden sm:block"
        onClick={handleShowMenu}
      />
      <div className={`inline-flex pl-2`}>
        <Link to="/" className={`w-10 h-10 rounded-lg float-left mr-2 z-[1] overflow-hidden hidden sm:block`}>
          <img src={logo} alt="logo" />
        </Link>
        <HiMenu onClick={handleShowMenu} className="mr-3 text-white text-lg z-[1] my-auto sm:hidden cursor-pointer" />
        <Link
          to="/"
          className={`h-10 leading-10 w-max text-white origin-left font-medium text-base duration-300 z-[1] m-auto select-none ${
            !open && 'sm:scale-0'
          }`}
        >
          iMES System
        </Link>
      </div>
      <span className="absolute block min-h-[50px] bg-primary w-full top-0 left-0"></span>
      {/* <div className="flex items-center rounded-md bg-white-200"></div> */}
      <ul onClick={() => (open ? setOpen(true) : setOpen(!open))} className={`pt-2 ${open ? '' : 'hidden sm:block'}`}>
        <div className={`${open ? '' : 'hidden'}  relative w-full outline-none`}>
          <div className=" z-50  flex absolute inset-y-0 right-0 items-center pr-5 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <SearchBarInSideBar />
        </div>
        {parentMenu
          ? parentMenu.map((parentItem: any, index: any) => (
              <MenuItem
                key={index}
                open={open}
                menuId={parentItem.menuId}
                menuName={parentItem.menuName}
                icon={parentItem.icon}
                childMenu={childMenu}
              />
            ))
          : ''}
      </ul>
    </div>
  );
});
