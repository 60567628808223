import { post, get } from '../utils/api';

export type ChildMenu = {
  menuId: string;
  icon: string;
  pageName: string;
  pageId: string;
  pageUrl: string;
  domainConfigUrl: string;
};

export type Menu = {
  menuId: string;
  icon: string;
  menuName: string;
};
export type WebPermission = {
  menuModel: Menu[];
  pageModel: ChildMenu[];
};

export type User = {
  accountId: string;
  token: string;
  userName: string;
  fullName: string;
  validaty: string;
  refreshToken: string;
  saleOrg: string;
  saleOrgName: string;
  companyId: string;
  companyCode: string;
  companyName: string;
  role: string;
  roles: string;
  expiredTime: Date;
  webPermission: WebPermission;
};

export interface UserRespone {
  isSuccess: boolean;
  data?: User;
  message: string;
}
export interface ChangePasswordResponse {
  isSuccess: boolean;
  data?: any;
  message: string;
}

export interface IUserServices {
  login(data: any): Promise<UserRespone>;
  checkAccount(userName: string): Promise<boolean>;
  changePassword(data: any): Promise<ChangePasswordResponse>;
}

export function createUserServices(url: string): IUserServices {
  return {
    login: async (data: any): Promise<UserRespone> => {
      return (await post(`Permission/Auth/Authenticate`, data)).data;
    },
    checkAccount: async (userName: string): Promise<boolean> => {
      return (await get(`Permission/Auth/CheckIsVendor?UserName=${userName}`))
        .data.data.isVendor;
    },
    changePassword: async (data: any): Promise<ChangePasswordResponse> => {
      return (await post(`Permission/Auth/ChangePassword`, data)).data;
    },
  };
}
