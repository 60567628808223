export const COMPANY_ROUTES = {
  BASE: 'company/*',
  LIST_COMPANY: {
    LINK: 'company/',
    PATH: '',
  },
  NEW_COMPANY: {
    LINK: 'company/new',
    PATH: 'new',
  },
};

export const ROUTES = {
  HOME: '/*',
  LOGIN: {
    BASE: 'login',
  },

  ALLOCATE_MATERIALS: {
    BASE: 'MES/AllocateMaterials/*',
    EDIT: {
      BASE: 'MES/AllocateMaterials/Edit',
    },
  },
  REPORT: {
    BASE: '/MES/AllocateReport',
  },
};
