import { post, put } from 'utils/api';
import { DataResponse } from './response';

export interface AllocateMaterialsModel {
  stt: number;
  id: string;
  // ma san pham
  productCode: string;
  // ten san pham
  productName: string;
  // số lượng xuất sử dụng trong ngày
  exportAmountInDay: number;
  // số lượng cần phân bổ
  allocateAmount: number;
  unit: string;
  // trạng thái
  status: number;
  statusCode: boolean | null;
  stepCode: string;
  // công đoạn
  stepName: string;
  // số lô
  batch: string;
  // plant
  plant: string;
  sloc: string;
  // tên sloc
  slocName: string;
  slocId: string;
  statusPB: string;
  fullName: string;
  soWbs: string; // Add this line
  allocateAmountWbs: number;
};

// response chi tiết phân bổ lệnh sản xuất
export interface DetailAllocateMaterial {
  taskId: string;
  lsx: string;
  productCode: string;
  productName: string;
  lsxQty: number;
  numberRecord?: number;
  slycTheoLenh: number;
  sldapb: number;
  slConLai: number;
  allocateAmount?: number;
  unit: string;
  summary?: string;
  soNumber: string;
  soLine: string;
  wbs?: string;
  slocId?: string;
  batch?: string;
  allocateDate?: string;
  stepCode?: string;
  visible: boolean;
}

export interface PhanBoWBSDetail {
  sowbs?: string;
  quantity?: number;
}

export interface DetailAllocateModel {
  productCode: string;
  productName: string;
  exportAmountInDay: number;
  allocateAmount: number;
  unit: string;
  status: number;
  statusCode?: any;
  stepCode: string;
  stepName: string;
  batch: string;
  plant: string;
  sloc: string;
  slocName: string;
  detailAllocateMaterials: DetailAllocateMaterial[];
  phanBoWBSDetail: PhanBoWBSDetail[];
}

// request lấy chi tiết phân bổ lệnh sản xuất
export type AMDetailRequest = {
  productCode: string;
  batch: string;
  sloc: string;
  plant: string;
  stepCode: string;
  allocateDate: string;
  isUpdate: boolean;
};

// WarehouseTransaction
// lấy danh sách theo SO / WBS theeo số lô SAP
export interface WarehouseTransactionModel {
  so?: string;
  soLine?: string;
  wbs?: string;
  unit: string;
  lsxdt?: string;
  quantity: number;
}

// Lấy dữ liệu lưu bảng tạm
export interface TempAllocate {
  quantity: number;
  so: string;
  soLine: string;
  taskId: string;
}

// báo cáo phân bổ
export interface ReportAllocate {
  detailReports: DetailReport[];
  slocs: string[];
}
export type DetailReport = {
  allocateDate: string;
  slocReportAllocates: SlocReportAllocates[];
};

export type SlocReportAllocates = {
  slocCode: string;
  msgDisplay: string;
  color: string;
  percent: string;
};

export interface IAllocateMaterialsServices {
  getListAllocateMaterials(data: any): Promise<DataResponse<AllocateMaterialsModel[]>>;
  // lấy thông tin chi tiết phân bổ
  getDetail(data: any): Promise<DataResponse<DetailAllocateModel>>;
  // Lưu phân bổ
  saveAllocateMaterial(data: any): Promise<DataResponse<null>>;
  getListSOWBSByBatch(data: any): Promise<DataResponse<WarehouseTransactionModel[]>>;
  //Lấy dữ liệu lưu tạm
  getTempAllocate(data: any): Promise<DataResponse<Array<TempAllocate>>>;
  // Cập nhật dữ liệu lưu tạm
  updateTempAllocate(data: any): Promise<DataResponse<null>>;
  // lấy danh sách báo cáo
  getReportAllocate(data: any): Promise<DataResponse<ReportAllocate>>;
}

export function createAllocateMaterialsService(): IAllocateMaterialsServices {
  return {
    getListAllocateMaterials: async (data: any) => {
      return (await post(`MES/AllocateMaterials/search`, data)).data;
    },
    getDetail: async (data: any) => {
      return (await post(`MES/AllocateMaterials/detail`, data)).data;
    },
    saveAllocateMaterial: async (data: any) => {
      return (await post('MES/AllocateMaterials/save', data)).data;
    },
    getListSOWBSByBatch: async (data: any) => {
      return (await post('MES/WarehouseTransaction/GetListSOWBSByBatch', data)).data;
    },
    getTempAllocate: async (data: any) => {
      return (await post('MES/AllocateMaterials/get-allocate', data)).data;
    },
    updateTempAllocate: async (data: any) => {
      return (await put('MES/AllocateMaterials/update-allocate', data)).data;
    },
    getReportAllocate: async (data: any) => {
      return (await post('MES/AllocateMaterials/report-allocate', data)).data;
    },
  };
}
