import { runInAction } from 'mobx';
import {
  AllocateMaterialsModel,
  IAllocateMaterialsServices,
  DetailAllocateModel,
  WarehouseTransactionModel,
  TempAllocate,
  ReportAllocate,
} from 'services/allocateMaterials_services';

export type AllocateMaterialsStore = {
  AllocateMaterialsResponse: {
    data: AllocateMaterialsModel[];
    message: string;
    isSuccess: boolean;
  };
  detailResponse: {
    data: DetailAllocateModel | undefined;
    message: string;
    isSuccess: boolean;
  };
  saveResponse: {
    message: string;
    isSuccess: boolean;
  };
  listSOWBSResponses: {
    message: string;
    isSuccess: boolean;
    data: WarehouseTransactionModel[];
  };
  tempAllocate: {
    message: string;
    isSuccess: boolean;
    data: TempAllocate[];
  };
  // cập nhật bảng tạm
  updateTempAllocateResponse: {
    message: string;
    isSuccess: boolean;
  };
  // Báo cáo phân bổ
  reportAllocateResponse: {
    message: string;
    isSuccess: boolean;
    data: ReportAllocate | undefined;
  };

  // State search phân bổ
  dataSearchAllocate: {
    status: string;
    allocateDate: string;
    plant: string;
    sloc: string;
  };
};
interface DataSearchAllocateModel {
  status: string;
  allocateDate: string;
  plant: string;
  sloc: string;
}

export interface IAllocateMaterialsPresenter {
  createStore(): AllocateMaterialsStore;
  getListAllocateMaterials(store: AllocateMaterialsStore, data: object): Promise<void>;
  getAMDetail(store: AllocateMaterialsStore, data: object): Promise<void>;
  saveAllocateMaterials(store: AllocateMaterialsStore, data: object): Promise<void>;
  getListSOWBSByBatch(store: AllocateMaterialsStore, data: object): Promise<void>;
  // Lấy dữ liệu lưu tạm
  getTempAllocate(store: AllocateMaterialsStore, data: object): Promise<void>;
  // Cập nhật dữ liệu lưu tạm
  updateTempAllocate(store: AllocateMaterialsStore, data: object): Promise<void>;
  // lấy danh sách báo cáo phân bổ
  getReportAllocate(store: AllocateMaterialsStore, data: object): Promise<void>;
  //set dataSearchAllocate
  setDataSearchAllocate(store: AllocateMaterialsStore, data: DataSearchAllocateModel): Promise<void>;
}

export function createAllocateMaterialsPresenter({
  allocateMaterialsServices,
}: {
  allocateMaterialsServices: IAllocateMaterialsServices;
}): IAllocateMaterialsPresenter {
  return {
    createStore() {
      return {
        AllocateMaterialsResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
        detailResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        saveResponse: {
          message: '',
          isSuccess: false,
        },
        listSOWBSResponses: {
          message: '',
          isSuccess: false,
          data: [],
        },
        tempAllocate: {
          message: '',
          isSuccess: false,
          data: [],
        },
        updateTempAllocateResponse: {
          message: '',
          isSuccess: false,
        },
        reportAllocateResponse: {
          message: '',
          isSuccess: false,
          data: undefined,
        },
        dataSearchAllocate: {
          status: 'null',
          allocateDate: new Date().toISOString().split('T')[0],
          plant: '',
          sloc: '',
        },
      };
    },
    getListAllocateMaterials: async (store, data) => {
      try {
        const response = await allocateMaterialsServices.getListAllocateMaterials(data);

        runInAction(() => {
          store.AllocateMaterialsResponse.data = response.data;
          store.AllocateMaterialsResponse.isSuccess = response.isSuccess;
          store.AllocateMaterialsResponse.message = response.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getAMDetail: async (store: AllocateMaterialsStore, data: object) => {
      try {
        const response = await allocateMaterialsServices.getDetail(data);
        runInAction(() => {
          store.detailResponse.data = response.data;
          store.detailResponse.message = response.message;
          store.detailResponse.isSuccess = response.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
    saveAllocateMaterials: async (store: AllocateMaterialsStore, data: object) => {
      try {
        const response = await allocateMaterialsServices.saveAllocateMaterial(data);
        runInAction(() => {
          store.saveResponse.message = response.message;
          store.saveResponse.isSuccess = response.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getListSOWBSByBatch: async (store: AllocateMaterialsStore, data: object) => {
      try {
        const response = await allocateMaterialsServices.getListSOWBSByBatch(data);
        runInAction(() => {
          store.listSOWBSResponses.message = response.message;
          store.listSOWBSResponses.isSuccess = response.isSuccess;
          store.listSOWBSResponses.data = response.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getTempAllocate: async (store: AllocateMaterialsStore, data: object) => {
      try {
        const response = await allocateMaterialsServices.getTempAllocate(data);
        runInAction(() => {
          store.tempAllocate.message = response.message;
          store.tempAllocate.isSuccess = response.isSuccess;
          store.tempAllocate.data = response.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateTempAllocate: async (store: AllocateMaterialsStore, data: object) => {
      try {
        const response = await allocateMaterialsServices.updateTempAllocate(data);
        runInAction(() => {
          store.updateTempAllocateResponse.message = response.message;
          store.updateTempAllocateResponse.isSuccess = response.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getReportAllocate: async (store: AllocateMaterialsStore, data: object) => {
      try {
        const response = await allocateMaterialsServices.getReportAllocate(data);
        runInAction(() => {
          store.reportAllocateResponse.message = response.message;
          store.reportAllocateResponse.isSuccess = response.isSuccess;
          store.reportAllocateResponse.data = response.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    setDataSearchAllocate: async (store: AllocateMaterialsStore, data: DataSearchAllocateModel) => {
      try {
        runInAction(() => {
          store.dataSearchAllocate = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
